import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

const AboutPageTemplate = ({title, content, contentComponent}) => {
  const PageContent = contentComponent || Content

  return (
    <div className='container'>
      <div className='columns'>
        <div className='column is-2 is-offset-1'>
          <h1 className='is-size-3 has-text-weight-semibold'>About</h1>
        </div>
        <div className='column is-7 is-offset-1'>
          <CSSTransition
            key={'about-page'}
            timeout={5000}
            classNames='element-appear'
            appear
            in
          >
            <PageContent className='content' content={content} />
          </CSSTransition>
        </div>
      </div>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
